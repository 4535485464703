import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const Footer = ({siteTitle}) => (

<footer>
  <div className="footer_menu">
    <div className="footer_menu_box">
      <ul>
        <li>
          <a href="#" className="footer_menu_parent">トップ</a>
        </li>
      </ul>
      <ul>
        <li>
          <a href="#" className="footer_menu_parent">入会のご案内</a>
          <ul>
            <li>
              <a href="#">新規入会</a>
            </li>
            <li>
              <a href="#">会員特典</a>
            </li>
            <li>
              <a href="#">協会誌のご案内</a>
            </li>
            <li>
              <a href="#">会員サービス</a>
            </li>
            <li>
              <a href="#">キャンペーン</a>
            </li>
          </ul>
        </li>
      </ul>
      <ul>
        <li>
          <a href="#" className="footer_menu_parent">マイページへ</a>
          <ul>
            <li>
              <a href="#">協会誌電子版</a>
            </li>
            <li>
              <a href="#">図書検索</a>
            </li>
            <li>
              <a href="#">会員情報変更</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div className="footer_menu_box wide">
      <ul>
        <li>
          <a href="#" className="footer_menu_parent">講習会</a>
          <ul>
            <li>
              <a href="#">講習会</a>
            </li>
            <li>
              <a href="#">JR北海道</a>
            </li>
            <li>
              <a href="#">JR東日本(東京事務所)</a>
            </li>
            <li>
              <a href="#">横浜派出（10条在・運転）</a>
            </li>
            <li>
              <a href="#">八王子派出（10条在・保守）</a>
            </li>
            <li>
              <a href="#">大宮派出（10条幹、列車見張員）</a>
            </li>
            <li>
              <a href="#">JR東海</a>
            </li>
            <li>
              <a href="#">JR西日本</a>
            </li>
            <li>
              <a href="#">JR四国</a>
            </li>
            <li>
              <a href="#">JR九州</a>
            </li>
            <li>
              <a href="#">西武鉄道</a>
            </li>
            <li>
              <a href="#">東急電鉄</a>
            </li>
            <li>
              <a href="#">小田急電鉄</a>
            </li>
            <li>
              <a href="#">首都圏新都市鉄道</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div className="footer_menu_box">
      <ul>
        <li>
          <a href="#" className="footer_menu_parent">出版物・刊行物</a>
          <ul>
            <li>
              <a href="#">販売図書リスト</a>
            </li>
            <li>
              <a href="#">購入方法</a>
            </li>
            <li>
              <a href="#">図書紹介</a>
            </li>
          </ul>
        </li>
      </ul>
      <ul>
        <li>
          <a href="#" className="footer_menu_parent">新着ニュース</a>
          <ul>
            <li>
              <a href="#">すべて</a>
            </li>
            <li>
              <a href="#">保安講習会日程</a>
            </li>
            <li>
              <a href="#">協会からのお知らせ</a>
            </li>
            <li>
              <a href="#">お知らせ</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div className="footer_menu_box">
      <ul>
        <li>
          <a href="#" className="footer_menu_parent">一般社団法人日本鉄道施設協会の紹介</a>
          <ul>
            <li>
              <a href="#">協会の概要</a>
            </li>
            <li>
              <a href="#">沿革</a>
            </li>
            <li>
              <a href="#">委員会活動</a>
            </li>
            <li>
              <a href="#">組織</a>
            </li>
            <li>
              <a href="#">支部活動</a>
            </li>
            <li>
              <a href="#">支部業務内容</a>
            </li>
            <li>
              <a href="#">会員構成</a>
            </li>
            <li>
              <a href="#">公開情報</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div className="footer_menu_box">
      <ul>
        <li>
          <a href="#">広告募集</a>
        </li>
        <li>
          <a href="#">よくあるご質問</a>
        </li>
        <li>
          <a href="#">アクセスマップ</a>
        </li>
        <li>
          <a href="#">サイトマップ</a>
        </li>
        <li>
          <a href="#">プライバシーポリシー</a>
        </li>
        <li>
          <a href="#">外部リンク</a>
        </li>
        <li>
          <a href="#">お問い合わせ</a>
        </li>
      </ul>
    </div>
  </div>
  <div className="copyright">
    <p>
      <span>一般社団法人日本鉄道施設協会</span>
      <br />2021 Japan Railway Civil Engineering Association all rights reserved.
    </p>
  </div>
</footer>
)


Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
