import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import $ from 'jquery'
import 'jquery.cookie'

// CSS読み込み

// 画像読み込み
import Logo  from "../common/images/logo.png"
import FontMinus from "../common/images/font_minus.png"
import FontPlus from "../common/images/font_plus.png"
import SearchBtn from "../common/images/search_btn.png"

class Header extends React.Component {

  componentDidMount = () => {
    // テスト用
    $('.nav_btn').on('click', function () {
      if ($(this).hasClass('open')) {
        $(this).removeClass('open');
        $('nav').slideUp();
      } else {
        $(this).addClass('open');
        $('nav').slideDown();
      }
    });
    
    
    var fs = $.cookie('fontSize');
    if(fs) {
      if( fs == "fontSize_plus" ) {
        $(this).css("opacity",".7");
        $("html").css("font-size","95%");
        $("#fontSize_minus").css("opacity","1");
      } else if ( fs == "fontSize_minus" ){
        $(this).css("opacity",".7");
        $("html").css("font-size","62.5%");
        $("#fontSize_plus").css("opacity","1");
      }
    }
    
    $(".fontsize_btn").on( "click", function(){
      var thisSize = this.id;
      $.cookie('fontSize', thisSize);
      if( thisSize == "fontSize_plus"){
        $(this).css("opacity",".7");
        $("html").css("font-size","95%");
        $("#fontSize_minus").css("opacity","1");
      }
      if( thisSize == "fontSize_minus") {
        $(this).css("opacity",".7");
        $("html").css("font-size","62.5%");
        $("#fontSize_plus").css("opacity","1");
      }
    });
  }

  render = (siteTitle) => {
    return (
  <header id="header">
  <div className="header_inner">
    <div className="header_logo">
      <h1>
        <img src={Logo} alt="JRCEA 一般社団法人 日本鉄道施設協会" className="imgFit"></img>
      </h1>
    </div>
    <div className="header_menu">
      <ul>
        <li className="header_menu_fontsize">表示サイズ 
          <div id="fontSize_minus" className="fontsize_btn">
            <img src={FontMinus} alt="ー" className="imgFit"></img>
          </div>
          <div id="fontSize_plus" className="fontsize_btn">
            <img src={FontPlus} alt="＋" className="imgFit"></img>
          </div>
        </li>
        <li>
          <a href="#">お問い合わせ</a>
        </li>
        <li>
          <a href="#">マイページへ</a>
        </li>
        <li className="header_menu_search">
          <form name="site_search">
            <input type="text" placeholder="サイト内検索"></input>
            <input type="image" src={SearchBtn}></input>
          </form>
        </li>
      </ul>
      <button className="nav_btn">
        <svg viewBox="0 0 44 44">
          <title id="btntitle">ナビゲーションボタン</title>
          <g>
            <line id="nbt1" fill="none" stroke="#000000" strokeWidth="2" strokeMiterlimit="10" x1="11" y1="12" x2="33" y2="12"></line>
            <line id="nbt2" fill="none" stroke="#000000" strokeWidth="2" strokeMiterlimit="10" x1="11" y1="22" x2="33" y2="22"></line>
            <line id="nbt3" fill="none" stroke="#000000" strokeWidth="2" strokeMiterlimit="10" x1="11" y1="32" x2="33" y2="32"></line>
          </g>
        </svg>
      </button>
    </div>
  </div>
  <nav>
    <ul>
      <li>
        <a href="index.html">HOME</a>
      </li>
      <li>
        <a href="#">協会について</a>
        <ul className="menu-second-lv">
          <li>
            <a href="#">沿革</a>
          </li>
          <li>
            <a href="officer.html">役員・理事</a>
          </li>
          <li>
            <a href="#">組織</a>
          </li>
          <li>
            <a href="#">会員構成</a>
          </li>
          <li>
            <a href="#">業務内容</a>
          </li>
          <li>
            <a href="#">表彰</a>
          </li>
          <li>
            <a href="#">資格認定・教育</a>
          </li>
          <li>
            <a href="#">支部活動</a>
          </li>
          <li>
            <a href="#">公開情報</a>
          </li>
        </ul>
      </li>
      <li>
        <a href="#">保安講習会</a>
        <ul className="menu-second-lv">
          <li>
            <a href="#">JR北海道</a>
          </li>
          <li>
            <a href="#">JR東日本</a>
          </li>
          <li>
            <a href="#">JR東日本［施設協会・横浜取扱分］</a>
          </li>
          <li>
            <a href="training_jreast_hachioji.html">JR東日本［施設協会・八王子取扱分］</a>
          </li>
          <li>
            <a href="training_jrcentral.html">JR東海</a>
          </li>
          <li>
            <a href="training_jrwest.html">JR西日本</a>
          </li>
          <li>
            <a href="#">JR四国</a>
          </li>
          <li>
            <a href="#">JR九州</a>
          </li>
          <li>
            <a href="#">西武鉄道</a>
          </li>
          <li>
            <a href="#">東急電鉄</a>
          </li>
          <li>
            <a href="#">小田急電鉄</a>
          </li>
          <li>
            <a href="#">首都圏新都市鉄道</a>
          </li>
        </ul>
      </li>
      <li>
        <a href="#">支部活動</a>
        <ul className="menu-second-lv">
          <li>
            <a href="#">北海道支部</a>
          </li>
          <li>
            <a href="#">東北支部</a>
          </li>
          <li>
            <a href="#">関東支部</a>
          </li>
          <li>
            <a href="#">中部支部</a>
          </li>
          <li>
            <a href="#">関西支部</a>
          </li>
          <li>
            <a href="#">中国支部</a>
          </li>
          <li>
            <a href="#">四国支部</a>
          </li>
          <li>
            <a href="#">九州支部</a>
          </li>
        </ul>
      </li>
      <li>
        <a href="#">出版・刊行物</a>
      </li>
      <li>
        <a href="#">入会のご案内</a>
        <ul className="menu-second-lv">
          <li>
            <a href="#">入会へのメッセージ</a>
          </li>
          <li>
            <a href="#">会員特典</a>
          </li>
          <li>
            <a href="#">入会方法</a>
          </li>
          <li>
            <a href="#">各種　用紙のダウンロード</a>
          </li>
          <li>
            <a href="#">会員登録変更</a>
          </li>
          <li>
            <a href="#">退会について</a>
          </li>
        </ul>
      </li>
      <li>
        <a href="#">会員のページ</a>
        <ul className="menu-second-lv">
          <li>
            <a href="#">ID/PW画面へ</a>
          </li>
          <li>
            <a href="#">協会誌電子版</a>
          </li>
          <li>
            <a href="#">保有図書検索</a>
          </li>
          <li>
            <a href="#">会員様向け教育</a>
          </li>
        </ul>
      </li>
      <li className="forSP">
        <a href="#">アクセスマップ</a>
      </li>
      <li className="forSP">
        <a href="#">サイトマップ</a>
      </li>
    </ul>
  </nav>
</header>

    );
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
